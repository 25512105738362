@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-BlackItalic.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-Regular.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-Bold.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-Italic.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-Medium.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-Black.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('./theme/fonts/CeraPro-Light.woff2') format('woff2'),
      url('./theme/fonts/CeraPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  height: 100vh;
}

.App {

}
